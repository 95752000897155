<template>
  <div class="select-tree__root">
    <label class="select-filter__title">{{ labelName }}</label>
    <multiselect
      ref="refMultiselect"
      :value="selectedVariants"
      :options="filterVariants"

      placeholder=""
      select-label="Выбрать"
      deselect-label="Удалить"
      selected-label="Выбрано"

      :multiple="true"
      :searchable="false"
      :show-labels="false"
      :group-select="true"

      class="select-nested-filter__multiselect"
      group-values="options"
      group-label="label"
      track-by="label"
      label="label"

      @select="multiselectSelect"
    >
      <template #caret="{ toggle }">
        <span
          class="multiselect__arrow-icon"
          @mousedown.prevent.stop="toggle"
        >
          <img
            src="~/assets/img/svg/common/arrow-up-yellow.svg"
            alt="arrow"
          >
        </span>
      </template>
      <template #selection>
        <span class="multiselect__single">{{ selectedVariants.length
          ? `Выбрано: ${selectedVariants.length}`
          : labelName
        }}
        </span>
      </template>
      <template #option="props">
        <li
          class="select-nested-filter__option"
          :class="{'--label': Boolean(props.option.$isLabel) }"
        >
          <span :class="`multiselect-checkbox ${getSelectCheckbox(props.option)}`" />
          <span
            class="label"
            v-html="getLabelOption(props)"
          />
        </li>
      </template>
    </multiselect>
  </div>
</template>

<script setup lang="ts">
import Multiselect from 'vue-multiselect';
import type { IFilter } from '#sitis/internal/controllers/filters/models/Filter';
import { ref, computed } from '#imports';
import { catalogStore as useCatalogStore } from '#sitis/stores/modules/catalog-store';

const emit = defineEmits<{
	onChangeFilterVariant: [
		slug: string,
		value: Record<string, string> | null,
	];
}>();
const catalogStore = useCatalogStore();

const { filter, selectedFilters, isDisabled = false } = defineProps<{
	filter: IFilter;
	selectedFilters: Record<string, any>;
	isDisabled?: boolean;
}>();

const refMultiselect = ref(null);
const timeOutSetFilter = ref<ReturnType<typeof setTimeout>>();
const selectedVariants = ref<Array<any>>([]);
const filterVariants = ref<Array<any>>([]);

const updateFilter = () => {
  if (selectedFilters && Object.keys(selectedFilters).length) {
    Object.keys(selectedFilters?.[filter.slug] || {}).forEach((t) => {
      if ((selectedVariants.value || []).find((val) => val.slug === t)) {
        return null;
      }

      selectedVariants.value.push({ ...filter.variants?.find((variant) => variant?.slug === t) });
    });
  } else {
    selectedVariants.value = [];
  }
};

const labelName = computed(() => (filter.name === 'Категория' ? 'Проект' : filter.name));

watch(() => selectedFilters, (newValue) => {
  if (JSON.stringify(selectedFilters) === '{}') {
    updateFilter();
  }

  updateFilter();
  if (isDisabled) {
    selectedVariants.value = [];
  }
});

watch(() => filter, () => {
  updateFilter();
  initialOptions();
});

const initialValue = () => {
  const filterSlug = filter.slug;
  const filterVariantsList = filter.variants;

  if (Object.keys(selectedFilters?.[filterSlug] || {}).length <= 0) {
    selectedVariants.value = [];

    return;
  }

  selectedVariants.value = Object
    .keys(selectedFilters?.[filterSlug] || {})
    .map((t) => filterVariantsList.find((b) => b.slug === t));
};

const initialOptions = () => {
  let variants = filter?.variants || [];
  const available = filter?.available || [];
  const categories = catalogStore?.categories || [];

  const options: Array<any> = [];

  variants = [...variants].filter((t: any) => available.includes(t.value))
    .filter((t: any) => categories.find((_t: any) => _t.slug === t.slug));

  const getChildren = (parentId: number): any => variants
    .filter((t) => t.parentId === parentId)
    .sort((a, b) => {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    })
    .map((item) => ({
      ...item,
      $isDisabled: !available.includes(item.value),
      options: getChildren(item.value)
    }));

  [...variants]
    .filter((t: any) => t.depth === 1)
    .forEach((variant) => {
      const item = {
        ...variant,
        id: variant.value,
        options: getChildren(variant.value)
      };
      options.push(item);
    });

  filterVariants.value = options;
};

const changeValue = () => {
  clearTimeout(timeOutSetFilter.value);

  const slugs: any = {};
  (selectedVariants.value || []).map((t: any) => t.slug).map((slug: any) => {
    slugs[slug] = slug;
  });

  timeOutSetFilter.value = setTimeout(() => {
    (refMultiselect?.value as any)?.deactivate();

    emit('onChangeFilterVariant', (String(filter.slug) || String(filter.id)), slugs);
  }, 500);
};

const multiselectSelect = (selectedOption: any) => {
  const selectedOptions = Array.isArray(selectedOption) ? selectedOption : [selectedOption];
  const newSelectedVariants = [...selectedVariants.value];

  selectedOptions.forEach((option: any) => {
    const findIndexOption = newSelectedVariants.findIndex((t) => t.slug === option.slug);

    if (findIndexOption > -1) {
      newSelectedVariants.splice(findIndexOption, 1);
    } else {
      newSelectedVariants.push(option);
    }
  });

  selectedVariants.value = newSelectedVariants;

  changeValue();
};

const getSelectCheckbox = (option: any) => {
  const values = [...selectedVariants.value].map((t) => t.value);
  if (option.$isLabel) {
    const options = [...filterVariants.value]
      .find((t: any) => t.label === option.$groupLabel)?.options
      .map((t: any) => t.value);

    const countOptions = options.length;

    const countSelected = options.filter((t: any) => Boolean(values.includes(t))).length;

    if (countSelected === 0) {
      return '';
    }

    if (countOptions === countSelected) {
      return 'checked';
    }

    if (countOptions !== countSelected) {
      return 'partial';
    }
  }

  if (values.includes(option.value)) {
    return 'checked';
  }
  return '';
};

const getLabelOption = ({ option }: any) => {
  if (option.$isLabel) {
    return option.$groupLabel;
  }
  if (option.description) {
    return `${option.label}`;
  }

  return option.label;
};

onMounted(() => {
  initialOptions();
  initialValue();

  nextTick(() => {
    updateFilter();
  });
});
</script>

<style lang="scss">
@import "@/assets/scss/media";

.multiselect__option--group {
  color: var(--primary);
}

.select-tree__root {
	& .multiselect__element {
		padding: 0;
    color: var(--primary) !important;

		&:hover {
			background: var(--primary-10);
		}
	}

	& .multiselect__content-wrapper {
		overflow-x: hidden;
	}

	& .multiselect__option {
		padding: 0;

		&--highlight {
			background: var(--primary-10) !important;
			margin: 0 -20px;
			padding: 0 20px !important;
      color: var(--primary);

			&::before {
				left: 20px;
				background: var(--white);
			}

			&::after {
				background: var(--primary-10) !important;
			}
		}
	}
}

.select-nested-filter__option {
	padding: 12px 16px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: 0.02em;

	.multiselect-checkbox {
		width: 20px;
		height: 20px;
		border-radius: 4px;
		margin-right: 8px;
		position: relative;

		//border: 1px solid rgb(226 221 233 / 50%);
		//box-shadow: 0 1px 4px 0 lightgray;
    background: #E2DDE9;

		&.checked {
			background-color: var(--secondary);
			border-color: var(--secondary);

			&::after {
				background-image: url("~/assets/img/svg/common/check-purple.svg");
			}
		}

		&.partial {
			background-color: var(--secondary);
			border-color: var(--secondary);

			&::after {
				background-image: url("~/assets/img/svg/common/minus-purple.svg");
			}
		}

		&::after {
			content: "";
			position: absolute;
			width: 12px;
			height: 12px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-size: contain;
		}
	}

	.label {
		flex: 1;

		span {
			color: #B1A5C5;
		}
	}

	&:not(.--label) {
		padding-left: 32px;
	}
}
</style>
